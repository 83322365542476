.no_warehouse_wrapper {
  .modal-video {
    width: 100%;
    height: 100%;
    object-fit: cover; /* 确保视频占满容器 */
  }
  .banner {
    position: relative;
    width: 100%;
    height: 100vh; /* 根据需要调整高度 */
    overflow: hidden;
  }

  .banner-video {
    width: 100%;
    // height: 100%;
    object-fit: cover;
  }

  .banner-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
  }

  .banner-overlay h1 {
    font-size: 3rem; /* 根据需要调整字体大小 */
    margin-bottom: 20px;
  }

  .banner-overlay button {
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    border-radius: 5px;
  }

  .carousel_wrapper_front {
    position: relative;

    .text_wrapper {
      position: absolute;
      bottom: 40%;
      left: 5%;

      .text {
        font-family: JDLANGZHENGTI-SB--GBK1, JDLANGZHENGTI-SB--GBK1;
        font-size: 44px;
        color: #111111;
        font-style: normal;
      }
    }

    .opt_btn {
      position: absolute;
      bottom: 25%;
      left: 5%;
      z-index: 1;
      width: 132px;
      height: 48px;
      line-height: 48px;
      text-align: center;
      background: #ffffff;
      font-size: 16px;
      font-weight: bold;
      color: #3875f6;
      cursor: pointer;
      border-radius: 2px;
    }
    .opt_video {
      position: absolute;
      top: 26%;
      right: 10%;
      z-index: 12;
      width: 533px;
      height: 305px;
      background: url("~@/images/noWarehouse/no-warehouse-video.png") no-repeat
        center;
      background-size: cover;
      cursor: pointer;
    }
  }

  .work_scene {
    // width: 100%;
    height: 64px;

    .store_rx {
      background: #ffffff;
      text-align: center;
      padding: 60px 0 55px;

      .title_big {
        color: #111111;
        font-size: 32px;
        margin-bottom: 35px;
      }
    }

    .no_warehouse_scene {
      background: #f8f9fd;
      text-align: center;
      padding-bottom: 80px;

      .title_big {
        color: #111111;
        font-size: 32px;
        padding: 50px 0 70px;
      }
      .main_content {
        display: flex;
        justify-content: center;
        .scene_bg {
          width: 1203px;
          height: 679px;
          position: relative;
        }
        .scene_hover1 {
          position: absolute;
          left: 316px;
          bottom: 66px;
          opacity: 0;
          transition: opacity 0.5s ease-in-out; /* 添加过渡效果 */
        }
        .scene_hover2 {
          position: absolute;
          right: 366px;
          top: 90px;
          opacity: 0;
          transition: opacity 0.5s ease-in-out; /* 添加过渡效果 */
        }
        .scene_hover1.show, .scene_hover2.show {
          opacity: 1;
        }
        .sceneHover1 {
          display: inline-block;
          position: absolute;
          left: 252px;
          bottom: 95px;
          width: 50px;
          cursor: pointer;
          .scene_person1 {
            display: inline-block;
            width: 50px;
            height: 118px;
            background: url("~@/images/noWarehouse/scene_person1.png") no-repeat
              center;
            background-size: 50px 118px;
          }
          .scene_gif1 {
            display: inline-block;
            width: 15px;
            height: 19px;
            background-image: url("~@/images/noWarehouse/scene_point.png");
            background-size: cover;
            background-position: center;
            animation: bounce 3s infinite;
            cursor: pointer;
            position: absolute;
            left: 8px;
            bottom: -18px;
          }
        }
        .sceneHover2 {
          display: inline-block;
          position: absolute;
          left: 472px;
          top: 190px;
          width: 60px;
          cursor: pointer;
          .scene_person2 {
            display: inline-block;
            width: 60px;
            height: 126px;
            background: url("~@/images/noWarehouse/scene_person2.png") no-repeat
              center;
            background-size: 60px 126px;
          }
          .scene_gif2 {
            display: inline-block;
            width: 15px;
            height: 19px;
            background-image: url("~@/images/noWarehouse/scene_point.png");
            background-size: cover;
            background-position: center;
            animation: bounce 3s infinite;
            cursor: pointer;
            position: absolute;
            right: 8px;
            bottom: 38px;
          }
        }
        @keyframes bounce {
          0%,
          20%,
          50%,
          80%,
          100% {
            transform: translateY(0);
          }
          40% {
            transform: translateY(-5px);
          }
          60% {
            transform: translateY(-2px);
          }
        }
      }
    }

    .order_complete_flow {
      text-align: center;
      padding: 60px 80px;
      background-color: #ffffff;

      .title_big {
        color: #111111;
        font-size: 32px;
      }
      .title_small {
        margin-bottom: 72px;
        color: #74757a;
      }
    }

    .customer_case {
      width: 100%;
      height: 760px;
      background: url("~@/images/noWarehouse/customer_case_bg.png") no-repeat
        center;
      background-size: cover;
      text-align: center;
      padding: 60px 0 40px;

      .title_small {
        margin-bottom: 40px;
        color: #ffffff;
      }

      .title_big {
        color: #ffffff;
        font-size: 32px;
        margin-bottom: 14px;
      }
    }
  }
}
.video-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  line-height: 510px;
  background-color: #111; /* 黑色背景 */
  color: #fff; /* 白色文字 */
  font-size: 18px;
}
