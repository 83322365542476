.apply_modal_wrapper {
    .hibo-modal-body {
        padding-top: 0;
    }
    .title {
        margin: 15px 0 10px;
    }
    .modal_wrap {
        width: 440px;
        height: 350px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #FFFFFF;
        box-shadow: 0px 2px 11px 7px rgba(164, 164, 164, 0.5);
        border-radius: 8px;
        text-align: center;
        z-index: 1;

        input {
            border: 2px solid #1D81FC;
            border-radius: 8px;
            outline: none;
            background-color: transparent;
            // caretColor: "#47b34f",
            width: 279px;
            height: 47px;
            padding: 0 24px;
            font-size: 18px;
            box-sizing: border-box;
            color: #333333;
            margin-top: 23px;
        }

        .inputWrap {
            margin-top: 26px;
        }

        .modal_title {
            font-size: 20px;
            height: 30px;
            line-height: 30px;
            color: #121212;
            font-weight: bold;
            margin-top: 20px;
        }

        .modal_desc {
            font-size: 14px;
            height: 16px;
            line-height: 16px;
            color: #1579F4;
            font-weight: bold;
            margin-top: 16px;
        }

        .modal_phone_tip {
            font-size: 14px;
            height: 20px;
            line-height: 20px;
            color: #999999;
            font-weight: 500;
            margin-top: 5px;
        }

        .modal_bottom {
            margin-top: 3px;
            text-align: center;

            div {
                width: 200px;
                height: 40px;
                background: linear-gradient(270deg, #1D81FC 0%, #5FB1FF 100%);
                font-size: 16px;
                font-weight: 500;
                color: #FFFFFF;
                margin: 0 auto;
                line-height: 40px;
                cursor: pointer;
            }

            .modal_phone {
                font-size: 14px;
                color: #999999;
                font-weight: 500;
                margin-top: 5px;
            }
        }

        .close {
            width: 40px;
            height: 40px;
            position: absolute;
            right: -10px;
            top: -10px;
            cursor: pointer;

            img {
                display: block;
                width: 40px;
                height: 40px;
            }
        }
    }
}