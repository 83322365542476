.market-form-label-normal {
  .form-label {
    margin-right: 10px;
  }
  .next-select.medium,
  .next-date-picker-medium .next-input,
  .next-date-picker-medium .next-icon {
    height: 38px;
    width: 160px;
    input {
      height: 36px;
    }
  }
  .next-date-picker-medium .next-icon {
    width: auto;
    margin-top: 4px;
  }
}

.radio_normal {
  .next-radio-wrapper {
    height: 38px;
    padding: 4px 16px;
  }
}
.radio-single-normal {
  label {
    .next-radio-label {
      margin-left: 10px;
      margin-right: 30px;
    }
  }
}
.dialog-normal {
  .next-dialog-header {
    font-weight: 500;
    background: #ffffff;
    color: rgba(51, 51, 51, 1);
    font-size: 18px;
  }
  .next-dialog-close {
    top: 9px;
    width: 18px;
    height: 18px;
    .next-icon-close:before {
      content: '';
    }
    .next-icon-close {
      width: 18px;
      height: 18px;
      background: url(../img/icon_close_blue.png) no-repeat 100% 100%;
      background-size: cover;
    }
  }
}

.combobox-normal {
  .next-select-inner-wrapper {
    .next-select-inner {
      margin-top: 3px;
      input {
        margin-top: 8px;
      }
    }
  }
}

.dialog-noclose-normal {
  .next-dialog-header {
    font-weight: 500;
    background: #ffffff;
    color: rgba(51, 51, 51, 1);
    font-size: 18px;
  }
  .next-dialog-close {
    .next-icon-close:before {
      content: '';
    }
  }
}

#selectCommon {
  width: 160px;
  height: 38px;
  font-size: 14px;
  padding-top: 5px;
  margin-left: 10px;
}
.inputComm {
  width: 160px;
  height: 38px;
  font-size: 14px;
  padding-top: 5px;
  margin-left: 10px;
}
.dialog-comfirm-gray {
  width: 66px;
  height: 30px;
  background: linear-gradient(56deg, rgba(158, 158, 158, 1) 0%, rgba(209, 209, 209, 1) 100%);
  border-radius: 15px;
  float: left;
  line-height: 30px;
  color: #fff;
}
.dialog-comfirm-blue {
  width: 66px;
  height: 30px;
  background: linear-gradient(228deg, rgba(83, 170, 245, 1) 0%, rgba(59, 131, 245, 1) 100%);
  box-shadow: 0px 2px 6px 0px rgba(57, 121, 242, 0.2);
  border-radius: 15px;
  float: left;
  line-height: 30px;
  color: #fff;
}

.dialog-cancel {
  width: 66px;
  height: 30px;
  float: left;
  line-height: 30px;
  color: #fff;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
}
.normal-pic {
  width: 82px;
  height: 82px;
  border: 1px solid rgba(238, 238, 238, 1);
  position: relative;
}

.next-select.selectCommon {
  width: 160px;
  height: 38px;
  font-size: 14px;
  padding-top: 5px;
  margin-left: 10px;
}
.next-select.selectCommon.multiple {
  width: auto;
  min-height: 38px;
  font-size: 14px;
  padding-top: 5px;
  margin-left: 10px;
}
.next-table-empty {
  display: none;
}
.next-icon-delete-filling {
  display: none!important;
}
.next-range-picker {
  border: 1px solid #e0e0e0;
}
.next-range-picker-show-time {
  min-width: 340px;
}
.stock-search-label {
  font-size: 14px;
  margin: 0 10px;
  margin-right: 0;
  text-align: left;
}
// 履约时间框样式
.date_picker_numal {
  height: 38px;
  border-color: #ccc;
  .next-range-picker-trigger {
    background-color: transparent;
  }
  .next-input {
    input {
      height: 36px;
      margin: 0;
      padding: 0 8px;
      font-size: 14px;
      border-radius: 4px;
    }
  }
  .next-icon {
    margin-top: 5px;
  }
}
.download-template-a {
  color: #ffffff;
  &:link {
    color: #ffffff;
  }
  &:visited {
    color: #ffffff;
  }
}
.lvyue-common-form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
  padding: 0 20px;
  .my-own-search {
    .next-comobobox {
      height: 38px;
    }
    .next-comobobox {
      input {
        height: 38px;
      }
    }
    .next-search.medium {
      .next-btn {
        height: 40px;
        line-height: 38px;
      }
    }
  }
}
.lvyue-common-search-button-wrap {
  justify-content: flex-start;
  flex: 1;
  display: flex;
  margin-bottom: 18px;
  min-width: 150px;
  align-items: center;
}

.lvyue-no-content-table {
  .next-table-body {
    display: none;
  }
}

.my-own-button {
  display: inline-block;
  margin: 0 10px;
  .normal {
    padding: 7px 25px;
    font-size: 14px;
    color: #ffffff;
    background: linear-gradient(228deg, rgba(83, 170, 245, 1) 0%, rgba(59, 131, 245, 1) 100%);
    box-shadow: 0px 2px 6px 0px rgba(57, 121, 242, 0.2);
    border-radius: 19px;
  }
  .reset {
    padding: 7px 25px;
    font-size: 14px;
    color: rgba(64, 139, 245, 1);
    box-shadow: 0px 2px 6px 0px rgba(57, 121, 242, 0.2);
    border-radius: 19px;
    border: 1px solid rgba(64, 139, 245, 1);
  }
  .reset2 {
    padding: 7px 10px;
    font-size: 14px;
    color: rgba(64, 139, 245, 1);
    border-radius: 19px;
    border: none;
  }
  .reset3 {
    padding: 7px 10px;
    font-size: 14px;
    color: #333333;
    border: none;
  }
}

.xc-my-own-dialog {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .next-dialog-body {
    color: #333333;
    text-align: center;
  }
  .next-dialog-header {
    text-align: left;
    background: #ffffff;
    font-weight: 500;
    padding: 20px 20px 0 20px;
  }
  .next-table-header-node {
    background: #fafbfd;
    color: rgba(51, 51, 51, 1);
    border: none;
  }
  .next-table-cell-wrapper {
    min-height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 3px !important;
    position: relative;
  }
  .next-dialog-close {
    display: none;
  }
}

.next-switch-off {
  background: #cacaca !important;
}
.next-switch-on {
  background: #3979f2 !important;
}

.next-date-picker-medium {
  .next-input input,
  .next-icon {
    height: 36px;
    line-height: 26px;
  }
}

.item-upload {
  padding: 0 12px;
  height: 30px;
  line-height: 30px;
  border-radius: 4px;
  background: #4e78c6;
  color: #fff;
  margin-left: 10px;
  cursor: default;
  font-size: 14px;
  a {
    color: #fff;
    margin-left: 15px;
  }
}

.next-cascader-select-dropdown {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  li {
    &.next-cascader-menu-item {
    }
    &:hover {
      background: rgba(57, 121, 242, 0.05);
    }
    &.expanded {
      background: rgba(57, 121, 242, 0.05);
      color: #3979f2;
    }
    &.js-selected {
      background: rgba(57, 121, 242, 0.05);
      color: #3979f2;
    }
  }
}
.ant-table-tbody > tr.ant-table-row:hover > td {
  background: #f5f8fe;
}
.ant-table-thead > tr > th {
  padding: 12px;
}
.ant-table-tbody > tr > td {
  padding: 12px;
}

.input-error {
  border: 1px solid #FE4A4A;
}

.hibo-toast-success{
  .ant-message-notice-content{
    padding: 10px 18px;
    background: #01C0AA;
    color: #fff;
    border-radius: 4px;
    *.ant-message-success{
      display: flex;
    }
  }
}
.hibo-toast-error{
  .ant-message-notice-content{
    padding: 10px 18px;
    background: #EE2E6B;
    color: #fff;
    border-radius: 4px;
    *.ant-message-error{
      display: flex;
    }
  }
}
.ant-message{
  z-index: 10000001;
}
.ant-notification {
  z-index: 9999;
}
.ant-notification-notice {
  background: #f3f9ff;
}

// 列表查询条件样式 start
.osOrder-wrapper {
  .search-form{
    display: flex;
    flex-wrap: wrap;
    .search-form-item{
      flex-grow: 0;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      height: 32px;
      line-height: 32px;
      margin-right: 20px;
      margin-bottom: 16px;
    }
    // 不需要折叠的按钮样式
    .search-form-btn-inline{
      flex-grow: 1;
      text-align: right;
      margin-bottom: 16px;
      .hb-btn, .zt-btn{
        height: 32px;
        line-height: 32px;
      }
    }
    // 文字标题
    .search-form-item-label{
      width: 84px;
      height: 32px;
      margin-right: 8px;
      line-height: 32px;
      color: #333;
      font-size: 14px;
      text-align: right;
      flex-shrink: 0;
      flex-grow: 0;
    }
  
    // 基本元素样式
    .next-select.medium{
      width: 100%;
      height: 32px;
      line-height: 30px;
      border-color: #ccc;
    }
    // input输入框
    .search-form-input{
      width: 100%;
      height: 32px;
      line-height: 32px;
      border: 1px solid #ccc;
      background-color: #fff;
      border-radius: 4px;
      padding: 0 4px;
    }
    // 下拉选项
    .search-form-select{
      width: 100%;
      height: 32px;
      line-height: 32px;
    }
    .ant-select.ant-cascader{
      width: 100%;
      .ant-select-selector{
        height: 32px;
        line-height: 32px;
        border-radius: 4px;
        border-color: #ccc;
      }
    }
  
    // 布局元素样式
    .search-form-item-control{
      display: flex;
      align-items: center;
    }
    // input和下拉选项的外层div样式
    .search-form-item-control-input, .search-form-item-control-select{
      width: 198px;
    }
    // 精确/模糊搜索的div样式
    .search-form-item-control-subSelect.next-select.medium{
      min-width: auto;
      width: 86px;
      padding: 0 15px 0 6px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    // 精确/模糊搜索类的input样式
    .search-form-input-subInput{
      width: 170px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: none;
    }
    .next-input-medium input {
      height: 30px;
    }
  
    // 时间控件带快捷按钮样式
    .search-form-item-control-btn-rangePicker{
      height: 32px;
      border-color: #ccc;
      border-radius: 0;
      border-left: none;
      border-right: none;
      
      .next-input input{
        height: 30px;
      }
      .next-range-picker-medium {
        border-radius: 0;
        border-left: none;
      }
      .next-range-picker-separator, .next-range-picker-input-separator{
        width: 6px;
      }
    }
    // 与时间控件连接的今日、昨天、近7天按钮
    .search-form-item-control-rangePickerBtn {
      .range-picker-button{
        font-size: 14px;
        width: 68px;
        height: 32px;
        border-radius:0px; 
        padding:0;
        text-align: center;
        border:1px solid #ccc;
      }

      .first-date-button{
        border-radius: 0;
      }
      .last-date-button{
        border-radius: 0px 4px  4px 0px; 
        border:1px solid #E0E0E0;
      }
    }

    // 下拉选项
    .next-select.search-form-select {
      width: 198px;
      height: 32px;
      padding-top: 0;
      margin: 0 8px 0 0;
      line-height: 30px;
    }
    .next-select.search-form-select.multiple{
      min-height: auto;
      height: 32px;
      line-height: 30px;
    }

    .next-range-picker-medium{
      width: 198px;
      height: 32px;
      .next-input{
        width: calc(50% - 3px);
        input{
          height: 30px;
        }
      }
      .next-range-picker-separator, .next-range-picker-input-separator{
        width: 6px;
      }
    }
  
    
  
  
  
  }
}
.CustomComboboxErp {
  width: 198px !important;
  height: 32px !important;
  input{
    width: 198px;
    height: 30px;
    line-height: 30px;
  }
}

// 列表查询条件样式 end

/* ant时间组件边框颜色重置 */
// .ant-picker-focused {
//   border-color: #ccc;
//   box-shadow: none;
// }
// .ant-picker:hover, .ant-picker-focused {
//   border-color: #ccc;
// }
// ant时间组件边角4px
// .ant-picker {
//   border-radius: 4px;
// }

// 新表格 - 表格底部自带滚动条-自定义样式
.hibo-table-container{
  .hibo-table-sticky-scroll{
    height: 8px !important;
    border-top: 0;
    .hibo-table-sticky-scroll-bar{
      border-radius: 3px;
      position: relative;
      top: -8px;
      &:hover{
        background-color: rgba(0, 0, 0, 0.5);
      }
    }
  }
  .hibo-table-body::-webkit-scrollbar {
    width:3px;
    height: 8px;
  }
  /* 滚动槽 */
  .hibo-table-body::-webkit-scrollbar-track {
    box-shadow: none;
    border: none;
  }
  /* 滚动条滑块 */
  .hibo-table-body::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    box-shadow: none;
    &:hover{
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
}
// 新表格 - 表格设置粘性头部且有自定义滚动条时，隐藏tbody的自带滚动条
.hibo-table-container:has(.hibo-table-sticky-scroll) .hibo-table-body::-webkit-scrollbar {
  visibility: hidden;
}

.wrapper {
  width: 1180px;
  margin: 0 auto;
  min-width: 1180px;
}
.min_width {
  min-width: 1180px;
}

