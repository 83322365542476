.about-wrapper {
    .carousel_wrapper_about {
        width: 100%;
    }

    .about_main_wrapper {
        height: 64px;

        .about_content {
            text-align: center;
            padding: 60px 0 80px;
            background-color: #ffffff;

            .wrapper {
                margin-bottom: 112px;

                .title {
                    text-align: center;

                    .title_big {
                        color: #111111;
                        font-size: 32px;
                        margin-bottom: 10px;
                    }
                    .title_small {
                        color: #74757A;
                    }

                }

                .content {
                    margin-top: 20px;
                    text-align: left;

                    p {
                        color: #111111;
                        font-size: 18px;
                        line-height: 40px;
                    }
                }
            }

            .report_wrapper {
                .title {
                    color: #111111;
                    font-size: 32px;
                }

                .content {
                    margin-top: 34px;

                    p {
                        margin-top: 40px;
                        font-size: 24px;
                        line-height: 33px;

                        a,
                        a:link,
                        a:active,
                        a:visited {
                            color: #3875F6;
                            text-decoration: underline;
                        }

                        a:hover {
                            color: #3875F6;
                            text-decoration: underline;
                            font-size: 28px;
                        }
                    }
                }
            }

        }
    }
}