.supermarket_page_wrapper {
  .carousel_wrapper_front {
    position: relative;
    display: flex;
    justify-content: center;

    .text_wrapper {
      position: absolute;
      bottom: 40%;
      left: 5%;

      .text {
        font-family: JDLANGZHENGTI-SB--GBK1, JDLANGZHENGTI-SB--GBK1;
        font-size: 44px;
        color: #111111;
        font-style: normal;
      }
    }

    .opt_btn {
      position: absolute;
      bottom: 25%;
      left: 5%;
      z-index: 1;
      width: 132px;
      height: 48px;
      line-height: 48px;
      text-align: center;
      background: #ffffff;
      font-size: 16px;
      font-weight: bold;
      color: #3875f6;
      cursor: pointer;
      border-radius: 2px;
    }
    .banner_carousel_container {
      position: absolute;
      bottom: -50px;
      width: 1050px;
      .banner_carousel_text {
        color: #ffffff;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
      }
      .small_carousel_wrapper {
        height: 95px;
        line-height: 95px;
        background: #ffffff;
        box-shadow: 2px 2px 15px 0px rgba(200, 200, 200, 0.5);
        border-radius: 10px;
        overflow: hidden;
      }
    }
  }

  .work_scene {
    // width: 100%;
    height: 64px;

    .supermarket_pain_spot {
      text-align: center;
      background-color: #ffffff;
      // padding: 60px 0 80px;
      padding: 110px 0 80px;

      .title_big {
        color: #111111;
        font-size: 32px;
        margin-bottom: 60px;
      }

      .pain_spot_icon {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .supermarket_rx {
      // width: 100%;
    }

    .supermarket_core_advantage {
      text-align: center;
      padding: 60px 0px 0px;
      background-color: #ffffff;

      .title_big {
        color: #111111;
        font-size: 32px;
        margin-bottom: 60px;
      }

      .card_top_wrapper {
        margin-top: 44px;

        .tabs_wrapper {
          display: flex;
          justify-content: space-around;
          align-items: center;

          .card_item {
            width: 100px;
            height: 100px;
            cursor: pointer;

            .icon {
              margin-bottom: 8px;
            }

            .content {
              font-size: 16px;
              color: #111111;
            }

            .content_active {
              width: 100%;
              color: #3875f6;
              font-weight: 600;
              border-bottom: 2px solid #3875f6;
              padding-bottom: 5px;
            }
          }
        }

        .card_content {
          // background: url('~@/images/frontWarehouse/bg_color.png') no-repeat center;
          // background-size: cover;
          background-color: #f5f6fd;
          overflow: hidden;
          height: 0;
          transition: height 0.5s ease-out;
        }

        .card_content.active {
          height: 600px;
        }

        .card_content.inactive {
          padding: 0;
        }

        .card_content_finance {
          // width: 100%;
          height: 600px;
          text-align: center;
          padding: 60px 100px 60px;
          // background: url('~@/images/frontWarehouse/finance_bg.png') no-repeat center;
          // background-size: cover;
          background-color: #f5f6fc;

          .title_big {
            color: #111111;
            font-size: 26px;
            margin-bottom: 10px;
          }

          .title_small {
            color: #74757a;
            margin-bottom: 40px;
            font-size: 16px;
          }

          .main_content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 80px;

            .left_content {
              .item {
                width: 360px;
                height: 80px;
                margin-bottom: 20px;
                box-shadow: 4px 4px 23px 3px rgba(193, 197, 205, 0.27);
                border-radius: 4px;
                transition: background-image 0.5s ease;
                padding: 20px;
                text-align: left;
                color: #111111;

                .title {
                  font-size: 16px;
                }

                .text {
                  color: #74757a;

                  &:hover {
                    color: #ffffff;
                  }
                }

                &.active {
                  color: #ffffff;
                  background: url("~@/images/supermarket/agreement_item_bg.png")
                    no-repeat center;
                  background-size: cover;

                  .text {
                    color: #ffffff;
                  }
                }
              }
            }
          }
        }
      }
    }

    .supermarket_service_standard {
      background: #ffffff;
      text-align: center;
      padding: 60px 0 80px;

      .title_big {
        color: #111111;
        font-size: 32px;
      }

      .title_small {
        font-size: 16px;
        color: #74757a;
        margin: 10px 0 60px;
      }

      .main_content {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        background-color: #ffffff;
        border: 1px solid #e6e6e6;
        border-radius: 20px;
        height: 250px;

        .content_item {
          width: 33.3%;
          height: 100%;
          border-right: 1px solid #e6e6e6;
          padding: 40px 36px 80px;
          color: #111111;
          position: relative;

          .ellipse_icon {
            position: absolute;
            top: 40%;
            right: -25px;
          }

          &:last-of-type {
            border-right: none;
          }

          .title {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 20px;

            .icon {
              display: inline-block;
              width: 8px;
              height: 8px;
              background-color: #3875f6;
            }

            .text {
              font-size: 22px;
              margin-left: 14px;
            }

            .work_date {
              width: 64px;
              height: 24px;
              line-height: 24px;
              background: #ebf1fe;
              border-radius: 0px 100px 100px 0px;
              margin-left: 10px;
              color: #3875f6;
              font-size: 18px;
            }
          }

          .item_wrapper {
            text-align: left;
            margin-left: 24px;

            .item_text {
              margin-bottom: 20px;
            }
          }
        }
      }
    }

    .after_service {
      background: #ffffff;
      text-align: center;
      // padding: 0px 42px 80px;
      padding-bottom: 80px;

      .title_big {
        color: #111111;
        font-size: 26px;
        margin-bottom: 50px;
      }

      .card_wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        // margin-top: 44px;

        .card_item {
          width: 300px;
          height: 235px;
          margin-right: 30px;
          background: linear-gradient(180deg, #f1f5ff 0%, #ffffff 100%);
          box-shadow: 4px 4px 9px 3px rgba(193, 197, 205, 0.27);
          border-radius: 4px;
          border: 2px solid #ffffff;
          transition: background-color 0.5s ease;

          &:hover {
            color: #ffffff;
            background: #3875f6;
          }

          .icon {
            width: 50px;
            height: 50px;
            margin: 40px 0 20px;
          }

          .title {
            margin-bottom: 18px;
            font-size: 18px;
            font-weight: bold;
          }

          .text {
            margin: 0 26px;
            color: #74757a;
            text-align: left;
          }
        }

        .card_item_left {
          display: flex;
          flex-direction: column;
          align-items: center;

          .icon {
            background: url("~@/images/supermarket/after_service.png") no-repeat
              center;
            background-size: cover;
          }

          &:hover {
            .icon {
              background: url("~@/images/supermarket/after_service_active.png")
                no-repeat center;
              background-size: cover;
            }

            .text {
              color: #ffffff;
            }
          }
        }

        .card_item_center {
          display: flex;
          flex-direction: column;
          align-items: center;

          .icon {
            background: url("~@/images/supermarket/new_training.png") no-repeat
              center;
            background-size: cover;
          }

          &:hover {
            .icon {
              background: url("~@/images/supermarket/new_training_active.png")
                no-repeat center;
              background-size: cover;
            }

            .text {
              color: #ffffff;
            }
          }
        }

        .card_item_right {
          display: flex;
          flex-direction: column;
          align-items: center;

          .icon {
            background: url("~@/images/supermarket/rx_service.png") no-repeat
              center;
            background-size: cover;
            width: 38px;
            height: 50px;
          }

          &:hover {
            .icon {
              background: url("~@/images/supermarket/rx_service_active.png")
                no-repeat center;
              background-size: cover;
            }

            .text {
              color: #ffffff;
            }
          }
        }
      }
    }

    .customer_case {
      width: 100%;
      height: 788px;
      background: url("~@/images/supermarket/customer_case_bg.png") no-repeat
        center;
      background-size: cover;
      text-align: center;
      padding: 60px 0 40px;

      .title_small {
        margin-bottom: 40px;
      }

      .title_big {
        color: #ffffff;
        font-size: 32px;
        margin-bottom: 14px;
      }

      .title_small {
        color: #ffffff;
      }
    }

    .partner_wrapper {
      text-align: center;
      padding: 60px 0 50px;
      background-color: #ffffff;

      .title_big {
        color: #111111;
        font-size: 32px;
        margin-bottom: 60px;
      }

      .item_wrapper {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;

        img {
          margin-right: 48px;
          margin-bottom: 48px;
        }
      }
    }
  }
}
