.front_warehouse_wrapper {
  .carousel_wrapper_front {
    position: relative;
    display: flex;
    justify-content: center;

    .text_wrapper {
      position: absolute;
      bottom: 40%;
      left: 5%;

      .text {
        font-family: JDLANGZHENGTI-SB--GBK1, JDLANGZHENGTI-SB--GBK1;
        font-size: 44px;
        color: #111111;
        font-style: normal;
      }
    }
    .opt_btn_wrapper {
      position: absolute;
      bottom: 25%;
      left: 5%;
      display: flex;
      .opt_btn {
        z-index: 1;
        width: 132px;
        height: 48px;
        line-height: 48px;
        text-align: center;
        background: #ffffff;
        font-size: 16px;
        font-weight: bold;
        color: #3875f6;
        cursor: pointer;
        border-radius: 2px;
      }
      .about_nowarehouse {
        margin-left: 10px;
        z-index: 1;
        width: 132px;
        height: 48px;
        line-height: 48px;
        text-align: center;
        background: #3875f6;
        font-size: 16px;
        font-weight: bold;
        color: #ffffff;
        cursor: pointer;
        border-radius: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          margin-left: 8px;
        }
      }
    }

    .banner_carousel_container {
      position: absolute;
      bottom: -75px;
      width: 1172px;
      .banner_carousel_text {
        color: #ffffff;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
      }
      .small_carousel_wrapper {
        height: 133px;
        line-height: 133px;
        text-align: center;
        background: #ffffff;
        box-shadow: 2px 2px 15px 0px rgba(200, 200, 200, 0.5);
        border-radius: 10px;
        overflow: hidden;
      }
    }
  }

  .work_scene {
    // width: 100%;
    height: 64px;

    .front_warehouse_pain_spot {
      text-align: center;
      // padding: 60px 0 80px;
      padding: 135px 0 80px;
      background-color: #ffffff;

      .title_big {
        color: #111111;
        font-size: 32px;
        margin-bottom: 60px;
      }

      .pain_spot_icon {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .front_warehouse_rx {
      background: #f8f9fd;
      text-align: center;
      padding: 60px 0 80px;

      .title_big {
        color: #111111;
        font-size: 32px;
        margin-bottom: 80px;
      }

      .main_content {
        display: flex;
        justify-content: center;
        align-items: center;

        .content_left {
          display: flex;
          flex-direction: column;
          justify-content: center;

          .item {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 70px;

            &:last-of-type {
              margin-bottom: 0;
            }

            .item_left {
              margin-right: 24px;
              text-align: right;

              .top {
                font-size: 18px;
                font-weight: bold;
              }

              .bottom {
                color: #666666;
                width: 340px;
              }
            }
          }
        }

        .content_center {
          width: 265px;
          height: 565px;
          margin: 0 70px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;

          .phone_black_border {
            width: 265px;
            height: 565px;
            // background: url('~@/images/frontWarehouse/phone_bg_new.png') no-repeat center;
            // background-size: cover;
            position: absolute;
            top: 0;
            z-index: 100;
            border: 4px solid #000000;
            box-shadow: 3px 3px 12px 1px rgba(184, 184, 184, 0.5);
            border-radius: 30px;
          }

          .carousel_wrapper {
            width: 260px;
            border-radius: 30px;
            overflow: hidden;

            .slick-slide {
              border-radius: 30px;
              /* 设置圆角大小 */
              overflow: hidden;
              /* 确保子元素不会溢出圆角边界 */
            }
          }
        }

        .content_right {
          display: flex;
          flex-direction: column;
          justify-content: center;

          .item {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 70px;

            &:last-of-type {
              margin-bottom: 0;
            }

            .item_right {
              margin-left: 24px;
              text-align: left;

              .top {
                font-size: 18px;
                font-weight: bold;
              }

              .bottom {
                color: #666666;
                width: 340px;
              }
            }
          }
        }
      }
    }

    .front_warehouse_business {
      background: #f8f9fd;
      text-align: center;
      // padding: 0px 42px 80px;
      padding-bottom: 80px;

      .title_big {
        color: #111111;
        font-size: 26px;
        margin-bottom: 50px;
      }

      .card_wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        // margin-top: 44px;

        .card_item {
          width: 335px;
          height: 240px;
          margin-right: 20px;
          background: linear-gradient(180deg, #f1f5ff 0%, #ffffff 100%);
          border-radius: 6px;
          border: 3px solid #ffffff;
          transition: background-color 0.5s ease;

          &:hover {
            color: #ffffff;
            background: #3875f6;
          }

          .icon {
            width: 50px;
            height: 50px;
            margin: 40px 0 20px;
          }

          .title {
            margin-bottom: 18px;
            font-size: 18px;
            font-weight: bold;
          }

          .text {
            margin: 0 42px;
            color: #74757a;
            text-align: left;
          }
        }

        .card_item_left {
          display: flex;
          flex-direction: column;
          align-items: center;

          .icon {
            background: url("~@/images/frontWarehouse/join_in_left.png")
              no-repeat center;
            background-size: cover;
          }

          &:hover {
            .icon {
              background: url("~@/images/frontWarehouse/join_in_left_active.png")
                no-repeat center;
              background-size: cover;
            }

            .text {
              color: #ffffff;
            }
          }
        }

        .card_item_center {
          display: flex;
          flex-direction: column;
          align-items: center;

          .icon {
            background: url("~@/images/frontWarehouse/join_in_center.png")
              no-repeat center;
            background-size: cover;
          }

          &:hover {
            .icon {
              background: url("~@/images/frontWarehouse/join_in_center_active.png")
                no-repeat center;
              background-size: cover;
            }

            .text {
              color: #ffffff;
            }
          }
        }

        .card_item_right {
          display: flex;
          flex-direction: column;
          align-items: center;

          .icon {
            background: url("~@/images/frontWarehouse/join_in_right.png")
              no-repeat center;
            background-size: cover;
          }

          &:hover {
            .icon {
              background: url("~@/images/frontWarehouse/join_in_right_active.png")
                no-repeat center;
              background-size: cover;
            }

            .text {
              color: #ffffff;
            }
          }
        }
      }
    }

    .core_advantage {
      text-align: center;
      padding: 60px 0px 0px;
      background-color: #ffffff;

      .title_big {
        color: #111111;
        font-size: 32px;
        margin-bottom: 60px;
      }

      .card_top_wrapper {
        margin-top: 44px;

        .tabs_wrapper {
          display: flex;
          justify-content: space-around;
          align-items: center;

          .card_item {
            width: 100px;
            height: 100px;
            cursor: pointer;

            .icon {
              margin-bottom: 8px;
            }

            .content {
              font-size: 16px;
              color: #111111;
            }

            .content_active {
              color: #3875f6;
              font-weight: 600;
              border-bottom: 2px solid #3875f6;
              padding-bottom: 5px;
            }
          }
        }

        .card_content {
          background: url("~@/images/frontWarehouse/bg_color.png") no-repeat
            center;
          background-size: cover;
          overflow: hidden;
          height: 0;
          transition: height 0.5s ease-out;
        }

        .card_content.active {
          height: 600px;
        }

        .card_content.inactive {
          padding: 0;
        }
      }

      .card_bottom_wrapper {
        margin-top: 44px;

        .tabs_wrapper {
          display: flex;
          justify-content: space-around;
          align-items: center;

          .card_item {
            width: 100px;
            height: 110px;
            cursor: pointer;

            .icon {
              margin-bottom: 8px;
            }

            .content {
              font-size: 16px;
              color: #111111;
            }

            .content_active {
              color: #3875f6;
              font-weight: 600;
              border-bottom: 2px solid #3875f6;
              padding-bottom: 5px;
            }
          }
        }

        .card_content {
          background: url("~@/images/frontWarehouse/bg_color.png") no-repeat
            center;
          background-size: cover;
          overflow: hidden;
          height: 0;
          transition: height 0.5s ease-out;
        }

        .card_content.active {
          height: 600px;
        }

        .card_content.inactive {
          padding: 0;
        }

        .card_content_finance {
          // width: 100%;
          height: 680px;
          text-align: center;
          padding: 60px 100px 60px;
          background: url("~@/images/frontWarehouse/finance_bg.png") no-repeat
            center;
          background-size: cover;

          .title_big {
            color: #111111;
            font-size: 26px;
            margin-bottom: 14px;
          }

          .title_small {
            color: #74757a;
            margin-bottom: 80px;
          }

          .main_content {
            display: flex;
            justify-content: center;
            align-items: center;

            .left_content {
              div {
                width: 428px;
                height: 82px;
                margin-bottom: 38px;
                box-shadow: 4px 4px 23px 3px rgba(193, 197, 205, 0.27);
                border-radius: 4px;
                transition: background-image 0.5s ease;
              }

              .top {
                background: url("~@/images/frontWarehouse/finance_sales_channel.png")
                  no-repeat center;
                background-size: cover;

                &:hover {
                  background: url("~@/images/frontWarehouse/finance_sales_channel_active.png")
                    no-repeat center;
                  background-size: cover;
                }
              }

              .center {
                background: url("~@/images/frontWarehouse/finance_purchase.png")
                  no-repeat center;
                background-size: cover;

                &:hover {
                  background: url("~@/images/frontWarehouse/finance_purchase_active.png")
                    no-repeat center;
                  background-size: cover;
                }
              }

              .bottom {
                // background: url('https://static.hiboos.com/static/yzt-home/frontWarehouse/finance_hiboos.png') no-repeat center;
                background: url("~@/images/frontWarehouse/finance_hiboos.png")
                  no-repeat center;
                background-size: cover;

                &:hover {
                  background: url("~@/images/frontWarehouse/finance_hiboos_active.png")
                    no-repeat center;
                  background-size: cover;
                }
              }
            }

            .right_content {
              width: 491px;
              height: 326px;
              background: url("~@/images/frontWarehouse/finance_right.png")
                no-repeat center;
              background-size: cover;
              margin-left: 64px;
              margin-bottom: 30px;
            }
          }
        }

        .card_content_shop {
          text-align: center;
          padding: 60px 100px 60px;
          // background-color: #F8F9FD;
          background: url("~@/images/frontWarehouse/bg_color.png") no-repeat
            center;
          background-size: cover;

          .title_big {
            color: #111111;
            font-size: 26px;
            margin-bottom: 14px;
          }

          .title_small {
            color: #74757a;
            margin-bottom: 20px;
          }

          .main_content {
            display: flex;
            justify-content: center;
            align-items: center;

            .left_content {
              width: 485px;
              height: 380px;
              // background: url('~@/images/frontWarehouse/shop_manage_left.png') no-repeat center;
              // background-size: cover;
              // margin-right: 20px;
            }

            .right_content {
              display: flex;
              justify-content: space-around;
              align-items: center;

              div {
                width: 200px;
                height: 435px;
                box-shadow: 8px 8px 24px 3px rgba(172, 182, 202, 0.27);
                border-radius: 10px;
              }

              .left {
                background: url("~@/images/frontWarehouse/shop_right_center_new.png")
                  no-repeat center;
                background-size: cover;
              }

              .center {
                background: url("~@/images/frontWarehouse/shop_right_left_new.png")
                  no-repeat center;
                background-size: cover;
              }

              .right {
                background: url("~@/images/frontWarehouse/shop_right_right_new.png")
                  no-repeat center;
                background-size: cover;
              }
            }
          }
        }

        .card_content_center_warehouse {
          text-align: center;
          padding: 60px 100px 60px;
          // background-color: #F8F9FD;
          background: url("~@/images/frontWarehouse/bg_color.png") no-repeat
            center;
          background-size: cover;

          .title_big {
            color: #111111;
            font-size: 26px;
            margin-bottom: 14px;
          }

          .title_small {
            color: #74757a;
            margin-bottom: 60px;
          }

          .main_content {
            display: flex;
            justify-content: center;
            align-items: center;

            .image-div {
              width: 279px;
              /* 默认小图尺寸 */
              height: 350px;
              margin: 10px;
              transition: all 0.5s ease;
              /* 平滑过渡效果 */
              background-size: cover;
            }

            .left_spread {
              width: 847px;
              height: 350px;
              background: url("~@/images/frontWarehouse/work_flow_active.png")
                no-repeat center;
              background-size: cover;
            }

            .left_fold {
              width: 279px;
              height: 350px;
              background: url("~@/images/frontWarehouse/work_flow.png")
                no-repeat center;
              background-size: cover;
            }

            .right_spread {
              width: 847px;
              height: 350px;
              background: url("~@/images/frontWarehouse/business_flow_active.png")
                no-repeat center;
              background-size: cover;
            }

            .right_fold {
              width: 279px;
              height: 350px;
              background: url("~@/images/frontWarehouse/business_flow.png")
                no-repeat center;
              background-size: cover;
            }
          }
        }
      }
    }

    .service_standard {
      height: 620px;
      background: #f8f9fd;
      text-align: center;
      padding-top: 60px;

      .title_big {
        color: #111111;
        font-size: 32px;
        margin-bottom: 14px;
      }

      .title_small {
        color: #74757a;
        margin-bottom: 60px;
      }

      .main_content {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .customer_case {
      width: 100%;
      height: 788px;
      background: url("~@/images/frontWarehouse/customer_case_bg.png") no-repeat
        center;
      background-size: cover;
      text-align: center;
      padding: 60px 0 40px;

      .title_small {
        margin-bottom: 40px;
        color: #ffffff;
      }

      .title_big {
        color: #ffffff;
        font-size: 32px;
        margin-bottom: 14px;
      }
    }

    .partner_wrapper {
      text-align: center;
      background-color: #ffffff;
      padding: 60px 0;
      .title_big {
        color: #111111;
        font-size: 32px;
        margin-bottom: 60px;
      }

      .item_wrapper {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;

        img {
          margin-right: 48px;
          margin-bottom: 48px;
        }
      }
    }
  }
}
