.carousel-wrap {
  // width: 1050px;
  // height: 95px;
  overflow: hidden;
  position: relative;
  .carousel-box {
    position: absolute;
    top: 0;
    left: 0;
    .carouselImg {
      // height: 75px;
      margin-right: 20px;
    }
  }
}
