@charset 'utf-8';

/**
  * 重置字体：
  * 1.设置默认font-family：
  *	iOS 4.0+ 使用英文字体 Helvetica Neue，之前的iOS版本降级使用 Helvetica，中文字体设置为华文黑体STHeiTi。
  * 原生Android下中文字体与英文字体都选择默认的无衬线字体。
  * 2.避免iPhone及Android在横竖屏时，浏览器自动调整字体大小问题 
 */
html {
    font-family: "PingFang SC","微软雅黑","宋体","Helvetica Neue", Helvetica, STHeiTi, Arial, sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    /* font-size: 62.5%; */
}

body,
button,
input,
select,
textarea {
    font-family: "PingFang SC","微软雅黑","宋体","Helvetica Neue", Helvetica, STHeiTi, Arial, sans-serif;
}

body {
    margin: 0;
    font-size: 14px;
    line-height: 1.5;
    color: #333;
    /* background-color: #fff; */
    background: #FAFAFA;
}

html body {
    font-family: "PingFang SC","微软雅黑","宋体","Helvetica Neue", Helvetica, STHeiTi, Arial, sans-serif;
}

/**
 * 清除内外边距：
 * 1.structural elements 结构元素
 * 2.list elements 列表元素
 * 3.text formatting elements 文本格式元素
 * 4.structural elements 结构元素
 * 5.table elements 表格元素
 */

body,
div,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
blockquote,
figure,
dl,
dt,
dd,
ul,
ol,
li,
pre,
fieldset,
lengend,
button,
input,
textarea,
optgroup,
select,
form th,
td {
    margin: 0;
    padding: 0;
}

body p {
    margin-bottom: 0px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: normal;
}

a {
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
}

a:active,
a:hover,
a:link,
a:visited {
    outline: 0;
    text-decoration: none;
}

.ell {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.ell2 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}

.fl {
    float: left;
}

.fr {
    float: right;
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

*:active,
*:focus {
    outline: 0;
}

* {
    -webkit-tap-highlight-color: transparent;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}


/**
 * 重置列表元素
 */

ul,
ol {
    list-style: none;
}


/**
 * 重置图片元素
 */

img {
    border: 0;
}


/**
 * 清除浮动
 */

.clearfix:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    height: 0;
}

.clearfix {
    *zoom: 1;
}


/**
 * 重置表格元素：
 * 1.表格单元格距离为0，border为单线
 * 2.解决table中都是‘字母’及'数字'时不折行的问题 
 */

table {
    border-collapse: collapse;
    border-spacing: 0;
    font-size: 14px;
}

table td {
    word-wrap: break-word;
    word-break: break-all;
}

i,
em {
    font-style: normal;
}

strong {
    font-weight: normal;
}


/**
 * 重置表单元素：
 * 1.取消按钮在iPhone下的默认风格
 * 2.设置input的placeholder属性的默认颜色
 */

input {
    border: 0;
    background-color: transparent;
}

input[type="submit"],
input[type="reset"],
input[type="button"],
button {
    -webkit-appearance: none;
    cursor: pointer;
    border: 0;
    outline: 0;
    -webkit-tap-highlight-color: transparent;
    background: none;
}

input[type="search"],
input[type="password"] {
    -webkit-appearance: none;
}


/* 取消关闭按钮 */

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

input[type="text"] {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
}


/* 移除input[type="number"]自带的上下箭头 */

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
}

input[type="number"] {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    -moz-appearance:textfield;
}

input,
select,
textarea {
    outline: 0;
}

textarea {
    resize: none;
}

button[disabled],
input[disabled] {
    cursor: default;
}
input::-moz-placeholder,
textarea::-moz-placeholder {
    color: #ccc;
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
    color: #ccc;
}
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    color: #ccc;
}

/* Firfox下按钮默认样式问题 */

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

optgroup {
    font-weight: bold;
}

small {
    font-size: 80%;
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sup {
    top: -0.5em;
}

sub {
    bottom: -0.25em;
}


/**
 * Correct `block` display not defined for any HTML5 element in IE 8/9.
 * Correct `block` display not defined for `details` or `summary` in IE 10/11 and Firefox.
 * Correct `block` display not defined for `main` in IE 11.
 */

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
    display: block;
}


/**
 * 1. Correct `inline-block` display not defined in IE 8/9.
 * 2. Normalize vertical alignment of `progress` in Chrome, Firefox, and Opera.
 */

audio,
canvas,
progress,
video {
    display: inline-block;
    vertical-align: baseline;
}


/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */

audio:not([controls]) {
    display: none;
    height: 0;
}


/**
 * Address `[hidden]` styling not present in IE 8/9/10.
 * Hide the `template` element in IE 8/9/11, Safari, and Firefox < 22.
 */

[hidden],
template {
    display: none;
}
#root-container {
    min-width:1200px;
    /* height:100%; */
    /* overflow-x: auto; */
}
.fw-500 {
    font-weight: 500;
}
.fw-400 {
    font-weight: 400;
}
.margin-top-30 {
    margin-top:30px
}
.margin-top-20 {
    margin-top:20px
}
.p-r {
    position: relative;
}
.p-a {
    position: absolute;
}
.m-t-10 {
    margin-top:10px  
}
.f-s-14 {
    font-size: 14px;
}
.l-h-14 {
    line-height: 14px;
}
.m-b-30 {
    margin-bottom: 30px;
}
.c-p{
    cursor: pointer;
}
.c-m {

    cursor: move;
}
.m-t-10 {
    margin-top:10px;
}
.m-t-20 {
    margin-top:20px;
}
.m-l-20 {
    margin-left:20px;
}
.m-l-20 {
    margin-left:20px;
}
.m-r-20 {
    margin-right:20px;
}
.red {
    color:red;
}

.t-a-r {
    text-align: right;
}