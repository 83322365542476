.takeout_page_wrapper {
  .carousel_wrapper_front {
    position: relative;

    .text_wrapper {
      position: absolute;
      bottom: 40%;
      left: 5%;

      .text {
        font-family: JDLANGZHENGTI-SB--GBK1, JDLANGZHENGTI-SB--GBK1;
        font-size: 44px;
        color: #111111;
        font-style: normal;
      }
    }

    .opt_btn_wrapper {
      position: absolute;
      bottom: 25%;
      left: 5%;
      z-index: 1;
      display: flex;
      align-items: center;
    }
    .opt_btn {
      width: 132px;
      height: 48px;
      line-height: 48px;
      text-align: center;
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
      border-radius: 2px;
    }
    .btn_default {
      color: #3875f6;
      background-color: #ffffff;
    }
    .btn_primary {
      color: #ffffff;
      background-color: #3875f6;
      position: relative;
      img {
        position: absolute;
        right: -5px;
        top: -10px;
      }
    }
    .banner_carousel_container {
      position: absolute;
      left: 5%;
      bottom: 24px;
      color: #cdc7c2;
      text-align: right;
      transition: opacity 0.5s ease-in-out;
      .text {
        margin-bottom: 10px;
      }
    }
    .fade-in {
      opacity: 1;
    }

    .fade-out {
      opacity: 0;
    }
  }

  .work_scene {
    // width: 100%;
    height: 64px;

    .business_pain_spot_wrapper {
      text-align: center;
      background-color: #ffffff;
      padding: 60px 0;

      .title_big {
        color: #111111;
        font-size: 32px;
        margin-bottom: 60px;
      }

      .pain_content {
        display: flex;
        justify-content: space-around;

        .pain_item {
          .big_text {
            color: #111111;
            font-size: 30px;
            margin: 20px 0 10px;
          }

          .small_text {
            color: #74757a;
          }
        }
      }
    }

    .store_rx {
      background: #f8f9fd;
      text-align: center;
      padding: 60px 0 80px;

      .title_big {
        color: #111111;
        font-size: 32px;
        margin-bottom: 54px;
      }

      .main_content {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ffffff;
        border: 1px solid #e6e6e6;
        border-radius: 20px;
        height: 350px;

        .content_item {
          width: 33.3%;
          height: 100%;
          border-right: 1px solid #e6e6e6;
          padding: 40px 26px 80px;
          color: #111111;
          position: relative;
          .ellipse_icon {
            position: absolute;
            top: 40%;
            right: -25px;
          }

          &:last-of-type {
            border-right: none;
          }

          .title {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 20px;
            color: #111111;

            .icon {
              display: inline-block;
              width: 8px;
              height: 8px;
              background-color: #3875f6;
            }

            .text {
              font-size: 22px;
              margin-left: 14px;
            }
          }

          .center_text {
            font-size: 16px;
            margin-bottom: 40px;
            text-align: left;
            margin-left: 25px;
            color: #111111;
          }

          .icon_wrapper {
            display: flex;

            .icon_item {
              margin-right: 24px;

              img {
                margin-bottom: 10px;
              }

              .icon_text {
                color: #74757a;
                font-size: 12px;
              }
            }
          }

          .background_image {
            position: absolute;
            right: 0;
            bottom: 0;
          }
        }
      }
    }

    .takeout_business {
      background: #f8f9fd;
      text-align: center;
      // padding: 0px 42px 80px;
      padding-bottom: 80px;

      .title_big {
        color: #111111;
        font-size: 26px;
        margin-bottom: 50px;
      }

      .card_wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        // margin-top: 44px;

        .card_item {
          width: 335px;
          height: 240px;
          margin-right: 20px;
          background: linear-gradient(180deg, #f1f5ff 0%, #ffffff 100%);
          border-radius: 6px;
          border: 3px solid #ffffff;
          transition: background-color 0.5s ease;

          &:hover {
            color: #ffffff;
            background: #3875f6;
          }

          .icon {
            width: 50px;
            height: 50px;
            margin: 40px 0 20px;
          }

          .title {
            margin-bottom: 18px;
            font-size: 18px;
            font-weight: bold;
          }

          .text {
            margin: 0 42px;
            color: #74757a;
            text-align: left;
          }
        }

        .card_item_left {
          display: flex;
          flex-direction: column;
          align-items: center;

          .icon {
            background: url("~@/images/takeout/chat_icon.png") no-repeat center;
            background-size: cover;
          }

          &:hover {
            .icon {
              background: url("~@/images/takeout/chat_icon_active.png")
                no-repeat center;
              background-size: cover;
            }

            .text {
              color: #ffffff;
            }
          }
        }

        .card_item_center {
          display: flex;
          flex-direction: column;
          align-items: center;

          .icon {
            background: url("~@/images/takeout/evaluate_icon.png") no-repeat
              center;
            background-size: cover;
          }

          &:hover {
            .icon {
              background: url("~@/images/takeout/evaluate_icon_active.png")
                no-repeat center;
              background-size: cover;
            }

            .text {
              color: #ffffff;
            }
          }
        }

        .card_item_right {
          display: flex;
          flex-direction: column;
          align-items: center;

          .icon {
            background: url("~@/images/takeout/business_icon.png") no-repeat
              center;
            background-size: cover;
          }

          &:hover {
            .icon {
              background: url("~@/images/takeout/business_icon_active.png")
                no-repeat center;
              background-size: cover;
            }

            .text {
              color: #ffffff;
            }
          }
        }
      }
    }

    .core_advantage {
      text-align: center;
      padding: 60px 80px;
      background-color: #ffffff;

      .title_big {
        color: #111111;
        font-size: 32px;
        margin-bottom: 60px;
      }

      .content_wrapper {
        height: 457px;
        background: #ffffff;
        box-shadow: 5px 5px 11px 3px rgba(193, 197, 205, 0.27);
        border-radius: 4px;
        display: flex;

        .item {
          width: 20%;
          height: 100%;
          padding: 0 30px;
          border-right: 1px solid #e6e6e6;
          color: #111111;

          .title {
            display: flex;
            align-items: center;
            padding: 40px 0 30px;
            border-bottom: 1px solid #e6e6e6;

            .title_text {
              margin-left: 10px;
              font-weight: bold;
            }
          }

          .content {
            .channel_icon {
              display: flex;
              justify-content: space-around;
              align-items: center;
              margin-top: 20px;
            }

            .content_item {
              background: url("~@/images/takeout/tick.png") no-repeat left
                center;
              background-size: 16px 16px;
              margin-top: 20px;
              text-align: left;
              padding-left: 26px;
            }
          }
        }
      }
    }

    .customer_case {
      width: 100%;
      // height: 788px;
      background-color: #f8f9fd;
      text-align: center;
      padding: 60px 0 40px;

      .title_small {
        margin-bottom: 40px;
      }

      .title_big {
        color: #111111;
        font-size: 32px;
        margin-bottom: 14px;
      }

      .title_min_big {
        color: #111111;
        font-size: 26px;
        margin: 60px 0 40px;
      }

      .title_small {
        color: #74757a;
      }

      .core_case {
        display: flex;
        justify-content: space-around;

        .core_case_item {
          display: flex;
          align-items: center;
          padding: 30px 70px;
          background: linear-gradient(180deg, #f1f5ff 0%, #ffffff 100%);
          box-shadow: 2px 4px 10px 0px rgba(193, 197, 205, 0.27);
          border-radius: 4px;
          border: 2px solid #ffffff;

          .item_text {
            color: #111111;
            font-size: 20px;
            margin-left: 30px;
          }
        }
      }

      .vs_wrapper {
        display: flex;
        justify-content: space-between;

        .title {
          margin-bottom: 20px;
        }

        .left {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;

          .content_item {
            color: #646692;
          }

          .bottom {
            font-size: 16px;

            .bottom_content {
              width: 110px;
              height: 70px;
              line-height: 70px;
              text-align: center;
              background-color: #9293a9;
              margin-top: 112px;
              color: #ffffff;
              margin-bottom: 10px;
            }

            .bottom_text {
              color: #74757a;
            }
          }
        }

        .center {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .bottom {
            font-size: 16px;
            margin-bottom: 24px;

            .up_icon {
              margin-bottom: 20px;
            }

            .bottom_text {
              color: #3875f6;
            }
          }
        }

        .right {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;

          .content_item {
            color: #3875f6;
          }

          .bottom {
            font-size: 16px;

            .bottom_content {
              width: 110px;
              height: 152px;
              line-height: 152px;
              text-align: center;
              background-color: #3875f6;
              margin-top: 30px;
              color: #ffffff;
              margin-bottom: 10px;
            }

            .bottom_text {
              color: #74757a;
            }
          }
        }

        .content_item {
          width: 444px;
          height: 50px;
          font-size: 16px;
          margin-bottom: 15px;
          background: #ffffff;
          box-shadow: 4px 4px 23px 3px rgba(193, 197, 205, 0.27);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
