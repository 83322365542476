.header_wrapper_d {
  width: 100%;
  height: 64px;
  // box-shadow: 0px 2px 4px 0px rgba(196, 196, 196, 0.5);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 101;
  position: fixed;
  top: 0;
  left: 0;
  transition: all 0.75s ease 0s;

  .header_main {
    display: flex;
    align-items: center;

    .jd_icon_wrapper {
      width: 75px;
      height: 26px;
      background: url("~@/images/homePage/jd_icon_d.png") no-repeat center;
      background-size: 75px 26px;
      margin-left: 35px;
    }

    .hiboos_icon_wrapper {
      width: 104px;
      height: 22px;
      background: url("~@/images/homePage/hiboos_icon_d.png") no-repeat center;
      background-size: 104px 22px;
      margin-left: 24px;
    }

    .switch_tab_wrapper {
      margin-left: 44px;

      .tab_item {
        display: inline-block;
        margin-left: 44px;
        color: #ffffff;
        cursor: pointer;
        font-size: 16px;
      }

      .tab_item_active {
        font-weight: bold;
        border-bottom: 2px solid #ffffff;
      }

      // antd menu样式
      .ant-menu {
        background: none;
        color: #ffffff;
        margin-left: 44px;
        font-size: 16px;
      }
    }
  }

  .header_right {
    margin-right: 35px;
    display: flex;
    align-items: center;
    .header_right_slogan {
      margin-right: 10px;
    }

    .header_right_telephone {
      display: flex;
      align-items: center;
      width: 160px;
      height: 32px;
      line-height: 32px;
      border: 1px solid #ffffff;
      margin-right: 20px;
      border-radius: 2px;

      .phone_icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-color: #3875f6;
        margin-left: 20px;
        transition: transform 0.5s ease;
      }

      .telephone {
        margin-left: 10px;
        color: #ffffff;
      }
    }

    .header_right_btn {
      width: 86px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      background: #ffffff;
      font-weight: bold;
      color: #3875f6;
      cursor: pointer;
      border-radius: 2px;
    }
  }
}
.header_wrapper_h {
  width: 100%;
  height: 64px;
  box-shadow: 0px 2px 4px 0px rgba(196, 196, 196, 0.5);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 101;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #ffffff;
  transition: all 0.75s ease 0s;

  .header_main {
    display: flex;
    align-items: center;

    .jd_icon_wrapper {
      width: 75px;
      height: 26px;
      background: url("~@/images/homePage/jd_icon_h.png") no-repeat center;
      background-size: 75px 26px;
      margin-left: 35px;
    }

    .hiboos_icon_wrapper {
      width: 104px;
      height: 22px;
      background: url("~@/images/homePage/hiboos_icon_h.png") no-repeat center;
      background-size: 104px 22px;
      margin-left: 24px;
    }

    .switch_tab_wrapper {
      margin-left: 44px;

      .tab_item {
        display: inline-block;
        margin-left: 44px;
        color: #111111;
        cursor: pointer;
        font-size: 16px;
      }

      .tab_item_active {
        color: #3875f6;
        font-weight: bold;
        border-bottom: 2px solid #3875f6;
      }
    }
  }

  .header_right {
    margin-right: 35px;
    display: flex;
    align-items: center;

    .header_right_slogan {
      margin-right: 10px;
    }
    
    .header_right_telephone {
      display: flex;
      align-items: center;
      width: 160px;
      height: 32px;
      line-height: 32px;
      border: 1px solid #3875f6;
      margin-right: 20px;
      border-radius: 2px;

      .phone_icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-color: #3875f6;
        margin-left: 20px;
        transition: transform 0.5s ease;
      }

      .telephone {
        margin-left: 10px;
        color: #3875f6;
      }
    }

    .header_right_btn {
      width: 86px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      background: #3875f6;
      font-weight: bold;
      color: #ffffff;
      cursor: pointer;
      border-radius: 2px;
    }
  }
}
