/*msg()*/
.vertical-middle-msg {
  position: fixed;
  z-index: 3000;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 120%;
  opacity: 1;
  transition: opacity 500ms ease-in-out;
  .ver-m-td {
      text-align: center;
      vertical-align: middle;
  }
  .msg-content {
      position: relative;
      top: -50px;
      display: inline-block;
      min-width: 140px;
      padding: 10px;
      color: #fff;
      font-size: 17px;
      line-height: 28px;
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.75);
      margin: 0 60px;
      .hasFocus,
    //   .noFocus {
    //       display: block;
    //       width: 30px;
    //       height: 30px;
    //       margin: 0 auto;
    //       background: url('../../images/focus.png') 0 0 no-repeat;
    //       background-size: 68px auto;
    //   }
      .hasFocus {
          background-position: 3px -38px;
      }
      .noFocus {
          background-position: -37px 4px;
      }
  }
  &.vertical-middle-msg-hide {
      opacity: 0;
  }
}
