.lineCircle-wrap {
    .line {
        display: inline-block;
        width: 90px;
        height: 2px;
        background: #12C350;
        vertical-align: middle;
    }
    .circle {
        display: inline-block;
        width: 10px;
        height: 10px;
        border: 4px solid #12C350;
        border-radius: 50%;
        vertical-align: middle;
    }
    span {
        font-size: 24px;
        color: #333;
        margin-left: 18px;
        vertical-align: middle;
    }
}
.title-wrap {
    .title-box {
        display: inline-block;
        position: relative;
        i {
            display: block;
            width: 27px;
            height: 27px;
            background: #2CD471;
            border-radius: 50%;
            position: absolute;
            left: -11px;
            top: 12px;
            z-index: -1;
        }
        .title {
            font-size: 26px;
            color: #333;
            font-weight: 600;
            span {
                font-size: 40px;
                color: #12C350;
            }
        }
    }
}
.efficiencyItem-wrap {
    display: inline-block;
    width: 270px;
    height: 224px;
    background: #FFFFFF;
    box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    text-align: center;
    margin: 45px 0 0 30px;
    vertical-align: middle;
    img {
        display: block;
        width: 70px;
        height: 76px;
        margin: 4px auto 0 auto;
    }
    .efficiencyItem-title{
        font-size: 24px;
        color: #333;
        font-weight: 600;
        line-height: 33px;
        margin-top: 10px;
    }
    .desc {
        height: 94px;
        padding: 12px 23px 15px 23px;
        box-sizing: border-box;
        font-size: 16px;
        line-height: 22px;
        color: #333;
        text-align: left;
    }
    .bottomLine {
        width: 100%;
        height: 7px;
        background: #2CD471;
        border-radius: 0px 0px 4px 4px;
    }
}
.costItem-wrap {
    width: 425px;
    height: 331px;
    display: inline-block;
    margin-left: 30px;
    .costLeft{
        display: inline-block;
        width: 79px;
        height: 283px;
        background: #2CD471;
        border-radius: 6px 0 0 6px;
        vertical-align: middle;
        padding: 104px 24px;
        font-size: 26px;
        color: #FFFFFF;
        font-weight: 600;
        box-sizing: border-box;
    }
    .costRight {
        display: inline-block;
        width: 346px;
        height: 330px;
        background: #FFFFFF;
        box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.2);
        border-radius: 6px;
        vertical-align: middle;
        position: relative;
        padding: 45px 60px 45px 65px;
        box-sizing: border-box;
        .part {
            width: 100%;
            height: 120px;
            box-sizing: border-box;
            position: relative;
            .partTitle {
                font-size: 24px;
                color: #333;
                font-weight: 600;
                line-height: 33px;
                text-align: left;
            }
            .partlistItemSpec {
                .specItem {
                    width: 30%;
                    display: inline-block;
                    color: #333;
                }
            }
            .partlistItem {
                font-size: 16px;
                color: #333;
                line-height: 22px;
                text-align: left;
            }
            img {
                width: 48px;
                height: 48px;
                position: absolute;
                top: 0;
                left: 0;
            }
        }
        .part2 {
            margin-top: 22px;
        }
        .tag {
            width: 173px;
            height: 205px;
            position: absolute;
            bottom: 0;
            right: 0;
            overflow: hidden;
            i {
                display: block;
                border-radius: 50%;
                background: rgba(44, 212, 113, 0.06);
                position: absolute;
            }
            .big {
                width: 190px;
                height: 190px;
                left: 0;
                bottom: -24px;
            }
            .small {
                width: 112px;
                height: 112px;
                right: -56px;
                bottom: 92px;
            }
        }
        .costRightAfter, .costRightBefore {
            display: block;
            content: "";
            width: 35px;
            height: 14px;
            background: #2CD471;
            border-radius: 7px;
            position: absolute;
            left: -14px;
        }
        .costRightBefore {
            top: 84px;
        }
        .costRightAfter {
            bottom: 84px;
        }
    }
}
.brandList-wrap{
    width:1180px;
    height:361px;
    margin:58px auto 0;
    overflow: hidden;
    position: relative;
    .brand-list-box{
        position: absolute;
        top: 0;
        left: 0;
        .brandListImg{
            // width: 7293px;
            height: 361px;
        }
    }
        
    .flur-before{
        width: 45px;
        height: 361px;
        background: url(../../images/flurbg.png) no-repeat;
        background-size: 45px 361px;
        position: absolute;
        top: 0;
        left: 0;
    }
    .flur-after{
        width: 45px;
        height: 361px;
        background: url(../../images/flurbg.png) no-repeat;
        background-size: 45px 361px;
        position: absolute;
        top: 0;
        right: 0;
        -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        transform: rotate(180deg);
        background-origin: padding-box;
    }
}
.systemStep-wrap {
    display: inline-block;
    width: 250px;
    height: 321px;
    padding: 30px;
    box-sizing: border-box;
    // border: 1px solid red;
    .systemStep-time {
        font-size: 50px;
        line-height: 60px;
        span {
            font-size: 16px;
            line-height: 22px;
        }
    }
    .systemStep-title {
        width: 150px;
        height: 40px;
        margin: 12px auto 0 auto;
        background: #ABFFC9;
        border-radius: 3px;
        font-size: 24px;
        color: #00A63A;
        line-height: 40px;
        font-weight: 600;
    }
    .systemStep {
        margin-top: 40px;
        .step {
            font-size: 18px;
            line-height: 25px;
            margin-top: 8px;
        }
    }
}
.systemServ-wrap {
    width: 333px;
    height: 218px;
    display: inline-block;
    vertical-align: middle;
    padding: 8px 35px;
    box-sizing: border-box;
    border-right: 2px dashed rgba(255, 255, 255, 0.3);
    img {
        display: block;
        width: 80px;
        height: 80px;
        margin: 0 auto;
    }
    p {
        font-size: 24px;
        font-weight: 600;
        line-height: 33px;
        margin-top: 13px;
    }
    div {
        margin-top: 18px;
        font-size: 18px;
        line-height: 25px;
    }
}