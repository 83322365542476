.apply_wrapper {
    background: #3875F6;
    text-align: center;
    padding: 40px 42px 24px;

    .title_big {
        color: #ffffff;
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 36px;
    }

    .btn_wrapper {
        display: flex;
        justify-content: center;

        .btn {
            display: inline-block;
            width: 132px;
            height: 48px;
            line-height: 48px;
            text-align: center;
            background: #ffffff;
            color: #3875F6;
            font-size: 16px;
            font-weight: bold;
            cursor: pointer;
            border-radius: 2px;
        }
    }

}