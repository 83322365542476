.home-page-wrapper {
  .navigation_modal {
    position: fixed;
    left: 0;
    bottom: 0;
  }

  .carousel_wrapper_home {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 38.07%;

    .btn_wrapper {
      position: absolute;
      bottom: 25%;
      left: 5%;
      z-index: 1;
      display: flex;
      align-items: center;
    }
    .btn {
      width: 132px;
      height: 48px;
      line-height: 48px;
      text-align: center;
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
      border-radius: 2px;
    }
    .btn_default {
      color: #3875f6;
      background-color: #ffffff;
    }
    .btn_primary {
      color: #ffffff;
      background-color: #3875f6;
      position: relative;
      img {
        position: absolute;
        right: -5px;
        top: -10px;
      }
    }
  }

  .work_scene {
    // width: 100%;
    height: 64px;

    .scene_wrapper {
      background-color: #ffffff;
      padding: 60px 0 42px;
      .tabs {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 32px;

        .tabs_item {
          display: inline-block;
          font-size: 26px;
          color: #111111;
          margin-left: 70px;
          cursor: pointer;
        }

        .tabs_item_active {
          color: #3875f6;
          font-weight: bold;
          border-bottom: 4px solid #3875f6;
        }
      }

      .wrapper {
        border: 1px solid #e6e6e6;
        border-radius: 10px;
        height: 600px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .service_standard {
      height: 500px;
      background: #f8f9fd;
      text-align: center;
      // padding: 60px 42px 0;
      padding-top: 60px;

      .title_big {
        color: #111111;
        font-size: 32px;
        margin-bottom: 14px;
      }

      .title_small {
        color: #74757a;
      }

      .card_wrapper {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-top: 44px;

        .card_item {
          width: 220px;
          height: 230px;
          background: linear-gradient(180deg, #f1f5ff 0%, #ffffff 100%);
          box-shadow: 4px 4px 9px 3px rgba(193, 197, 205, 0.27);
          border-radius: 4px;
          border: 3px solid #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          overflow: hidden;

          .text {
            font-size: 16px;
            font-weight: 500;
            margin-top: 16px;
          }

          .popup {
            background: linear-gradient(180deg, #f1f5ff 0%, #ffffff 100%);
            position: absolute;
            bottom: -100%;
            /* 初始状态下，popup 完全隐藏在 container 下方 */
            left: 0;
            width: 100%;
            height: 100%;
            text-align: center;
            padding: 50px 20px 0;
            transition: bottom 0.5s;

            /* 平滑过渡效果 */
            .text {
              color: #3875f6;
              font-weight: bold;
              font-size: 16px;
              margin-bottom: 16px;
            }

            .text_content {
              color: #74757a;
              text-align: left;
            }
          }
        }

        .card_item:hover .popup {
          bottom: 0;
          /* 鼠标悬停时，popup 上移至完全显示 */
        }
      }
    }

    .service_tech {
      height: 720px;
      text-align: center;
      padding: 60px 42px 80px;
      background-color: #ffffff;

      .title_big {
        color: #111111;
        font-size: 32px;
        margin-bottom: 14px;
      }

      .title_small {
        color: #74757a;
      }

      .main_content {
        display: flex;
        height: 425px;
        margin-top: 44px;
        background: url("~@/images/homePage/service_tech.png") no-repeat center;
        background-size: cover;

        .left_content {
          width: 26%;
          height: 100%;
          color: #ffffff;

          .item {
            height: 30%;
            padding-top: 5%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .item_wrapper {
              width: 160px;
              text-align: left;

              .big_text {
                font-size: 36px;
                font-weight: 500;
              }
            }
          }
        }

        .right_content {
          width: 74%;
          height: 100%;
          display: flex;

          .item {
            width: 33%;
            margin: 100px 0;
            padding: 0 20px;
            border-right: 1px solid #7888a9;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: #ffffff;
            position: relative;
            overflow: hidden;
            transition: justify-content 0.3s ease;

            &:last-of-type {
              border-right: none;
            }

            .content {
              transition: transform 0.5s ease;

              .text {
                margin-top: 24px;
                font-size: 18px;
                font-weight: 500;
              }
            }

            .description {
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              padding: 0 20px;
              text-align: left;
              color: #e8ecf5;
              font-size: 12px;
              transition: transform 0.5s ease;
              /* 平滑过渡效果 */
              transform: translateY(100%);
              /* 初始状态下，description 完全隐藏在 container 下方 */
            }
          }

          .item:hover .description {
            transform: translateY(0);
            /* 鼠标悬停时，description 上移至完全显示 */
          }

          .item:hover .content {
            transform: translateY(-40%);
            /* 鼠标悬停时，content 上移 */
          }
        }
      }
    }

    .security_certificate {
      height: 380px;
      background: #f8f9fd;
      text-align: center;
      padding: 60px 42px 72px;

      .title_big {
        color: #111111;
        font-size: 32px;
        margin-bottom: 72px;
      }

      .title_small {
        color: #74757a;
      }

      .brandList-wrap {
        width: 100%;
        height: 122px;
        margin: 58px auto 0;
        overflow: hidden;
        position: relative;

        .brand-list-box {
          position: absolute;
          top: 0;
          left: 0;

          .brandListImg {
            margin-left: 60px;
            height: 122px;
          }
        }

        .flur-before {
          width: 45px;
          height: 122px;
          background: url(../../images/flurbg.png) no-repeat;
          background-size: 45px 122px;
          position: absolute;
          top: 0;
          left: 0;
        }

        .flur-after {
          width: 45px;
          height: 122px;
          background: url(../../images/flurbg.png) no-repeat;
          background-size: 45px 122px;
          position: absolute;
          top: 0;
          right: 0;
          -webkit-transform: rotate(180deg);
          -ms-transform: rotate(180deg);
          -moz-transform: rotate(180deg);
          transform: rotate(180deg);
          background-origin: padding-box;
        }
      }
    }
  }
}
