.fixed_wrapper {
    position: fixed;
    right: 0;
    bottom: 30%;
    z-index: 101;
    .fixed_wrapper_content {
        width: 112px;
        height: 240px;
        background-color: #3875F6;
        box-shadow: 0px 4px 7px 0px rgba(195, 197, 201, 0.53);
        border-radius: 100px 100px 4px 4px;
        opacity: 0.9;
        color: #ffffff;
        text-align: center;
        margin-bottom: 10px;

        .title {
            padding: 15px 0 6px;
            font-size: 14px;
            font-weight: bold;
        }

        .service_title {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 12px 0 6px;
            font-size: 14px;
            font-weight: bold;

            img {
                margin-right: 5px;
            }
        }

        .phone_number {
            font-size: 16px;
            font-weight: bold;
            font-family: Helvetica, Helvetica;
            margin-bottom: 6px;
        }

        .try_btn_wrapper {
            display: flex;
            justify-content: center;

            .try_btn {
                display: inline-block;
                width: 72px;
                height: 25px;
                background-color: #ffffff;
                color: #3875F6;
                font-size: 12px;
                font-weight: bold;
                border-radius: 2px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }
        }
    }

    .to_top_wrapper {
        width: 112px;
        height: 32px;
        border-radius: 4px;
        box-shadow: 0px 4px 7px 0px rgba(195, 197, 201, 0.53);
        background-color: #3875F6;
        opacity: 0.9;
        font-size: 12px;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        cursor: pointer;
        img {
            margin-right: 8px;
        }
    }
}