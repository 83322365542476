.navigation_modal_wrapper {
    width: 370px;
    background: #FFFFFF;
    box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
    border-radius: 2px;
    position: fixed;
    left: 20px;
    bottom: 10px;
    z-index: 2147483647;
    padding: 20px;


    .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #111111;
        margin-bottom: 40px;
    }

    .item_content {
        .item {
            width: 330px;
            padding: 10px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #F4F6FC;
            margin-bottom: 20px;
            color: #3875F6;
            font-size: 18px;
            cursor: pointer;
        }
    }
}